// From Dan Abramov: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import { useEffect, useRef } from 'react';

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback])

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null && delay > 0) {
            const id = setInterval(tick, delay)
            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);
}