import React, { useEffect } from 'react';

const UserLogin = (props) => {
    const tenant = props.tenant;

    useEffect(() => {
        const callback = `${window.location.protocol}//${window.location.host}/auth`;  // "https://mfp.stratus.lol/auth";
        const state = window.btoa(
            `${tenant?.userPoolId}|${tenant?.userPool?.Domain}|${tenant?.clientId}|${callback}|${tenant.region}|${tenant.id}|${tenant.name}`
        );
        const logoutUrl = `${window.location.protocol}//${window.location.host}/logout`;  // "https://mfp.stratus.lol/logout";
        const params = new URLSearchParams({
            "client_id": tenant.clientId,
            "response_type": "code",
            "redirect_uri": callback,
            "logout_uri": logoutUrl,
            "scope": "email openid profile",
            "state": state
        });
        // const authURL = `${tenant.slug}.auth.${tenant.region}.amazoncognito.com`
        // TODO Remove special handling for SEC tenant when ACS URL is updated. Ask Matt and James about it
        const authURL = tenant.name.toLowerCase() === 'sec' ? 'sec.auth.stratus.lol' : `${tenant.slug}.auth.${tenant.region}.amazoncognito.com`

        window.location.replace(
            `https://${authURL}/login?${params.toString()}`
        );
    }, [tenant]);

    return (
        <div>

        </div>
    )
}

export default UserLogin