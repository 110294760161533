import { makeStyles } from '@mui/styles'

import loadingIcon from '../../img/loading.gif';

const useStyles = makeStyles({
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h1': {
            fontSize: '1.5em',
            fontWeight: '400'
        }
    }
});

const Loading = () => {
    const classes = useStyles();
    return (
        <div className={classes.loading} >
            <img src={loadingIcon} alt="Loading..." />
            <h1>Loading...</h1>
        </div>
    )
}

export default Loading;