import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Auth from './components/Auth'
import Logout from './components/Logout'
import MFP from './components/MFP'
import Processing from './components/Processing'
import Redirect from './components/test/Redirect'
import FromDipa from './components/FromDipa'
import ToDipa from './components/ToDipa'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/auth">
          <Auth/>
        </Route>
        <Route path="/logout">
          <Logout/>
        </Route>
        <Route path="/mobile">
          MOBILE
        </Route>
        <Route path="/processing">
          <Processing/>
        </Route>
        <Route path="/redirect">
          <Redirect/>
        </Route>
        <Route path="/dipa-redirect">
          <FromDipa/>
        </Route>
        <Route path="/dipa">
          <ToDipa/>
        </Route>
        <Route path="/">
          <MFP/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
