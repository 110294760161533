import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import Loading from '../Loading';

const useStyles = makeStyles({
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& *': {
            margin: '8px'
        }
    }
});

const TenantSelector = (props) => {
    const [tenant, setTenant] = useState('');
    const classes = useStyles();

    const handleChange = (event) => {
        setTenant(event.target.value);
    }

    if (props.loading) {
        return <Loading />
    }

    return (
        <div className={classes.box}>
            <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
            <Select
                labelId="tenant-select-label"
                id="tenant-select"
                value={tenant}
                onChange={handleChange}
                label="Select Tenant"
                sx={{
                    width: "346px"
                }}
            >
                {props?.tenants.map(tenant => {
                    return <MenuItem value={tenant} key={tenant.slug}>{tenant.name}</MenuItem>
                })}
            </Select>
            <Button
                variant="contained"
                disableElevation
                onClick={() => props.onClick(tenant)}
                sx={{
                    width: "121px",
                    height: "42px",
                    margin: "32px"
                }}
            >
                OK
            </Button>
        </div>
    )
}

export default TenantSelector