import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { getToken, getUserPoolClient } from '../../services/api';

const Auth = (_props) => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        async function handleLogin() {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            const state = params.get('state');
            const paramState = window.atob(state);
            const [pPoolId, pDomain, pClientID, pCallback, pRegion, tenantId, tenantName] = paramState.split('|', 7);
            const client = await getUserPoolClient(pPoolId, pClientID, pRegion);
            const clientSecret = client.client.ClientSecret
            const token = await getToken(pDomain, pRegion, code, pClientID, clientSecret, pCallback);
            const accessToken = token.access_token;
            const idToken = token.id_token;
            const refreshToken = token.refresh_token;
            // Get username. Username is different for SSO and Cognito users
            const decodedIdToken = jwtDecode(idToken);
            const decodedAccessToken = jwtDecode(accessToken);
            const user = decodedIdToken.email || decodedAccessToken.username
            history.push('/', { user, tenant: { id: tenantId, name: tenantName, slug: pDomain, region: pRegion }, idToken, accessToken, refreshToken, client: client.client })
        }
        handleLogin();
    }, [history, location.search]);

    return (
        <></>
    )
}

export default Auth