import { useLocation } from 'react-router-dom';

import { reportEnd } from '../../../services/api';

const Redirect = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const reportSuccess = async (token, url) => {
        await reportEnd(token, true, url);
    }

    const reportFailure = async (token, url) => {
        await reportEnd(token, false, url);
    }

    const returnToProc = () => {
        const queryString = new URLSearchParams({arn: params.get('arn')});
        window.location = `https://mfp.stratus.lol/processing?${queryString.toString()}`
    }

    return (
        <>
            <>
                <div>Full query parameters:</div>
                <div>{params.toString()}</div>
            </>
            <>
                <div>Arn:</div>
                <div>{params.get("arn")}</div>
            </>
            <>
                <div>Token:</div>
                <div>{params.get("token")}</div>
            </>
            <>
                <div>Callback URL:</div>
                <div>{params.get("url")}</div>
            </>
            <>
                <button onClick={() => reportSuccess(params.get("token"), params.get("url"))}>Report Success</button>
                <button onClick={() => reportFailure(params.get("token"), params.get("url"))}>Report Failure</button>
                <button onClick={() => returnToProc()}>Return to Processing</button>
            </>
        </>
    )
}

export default Redirect