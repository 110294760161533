import jwtDecode from 'jwt-decode';
import StackTrace from 'stacktrace-js';
import { useHistory, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';

import Header from '../Header';
import Loading from '../Loading';
import TenantSelector from '../TenantSelector';
import UserLogin from '../UserLogin';
import Workflows from '../Workflows';
import RocketImg from '../../img/rocket.png';

import {
    getWorkflows,
    startWorkflow,
    getTenants,
    getUserPoolAll,
    refreshToken as refreshT
} from '../../services/api';

const useStyles = makeStyles({
    controls: {
        display: 'inline-flex',
        justifyContent: 'end',
        width: '73%'
    },
    header: {
        background: 'black',
        color: 'white',
        height: 32,
        padding: 8
    },
    logo: {
        display: 'inline-flex'
    },
    resultContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        '& *': {
            margin: '12px'
        }
    },
    title: {
        fontSize: '20px',
        margin: 8
    },
    toolbar: {
        alignContent: 'space-around',
        background: 'lightgray',
        display: 'flex',
        height: '48px',
        justifyContent: 'flex-end'
    },
    page: {
        position: 'relative',
        top: 65
    }
});

const MFP = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [wfx, setWFX] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [user, setUser] = useLocalStorage('user', '');
    const [tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [tenantSelected, setTenantSelected] = useState(false);
    const [accessToken, setAccessToken] = useLocalStorage('accessToken', undefined);
    const [_idToken, setIdToken] = useLocalStorage('idToken', undefined);
    const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', undefined);
    const [client, setClient] = useLocalStorage('client', undefined);

    useEffect(() => {
        const stateTenant = location.state?.tenant;
        const stateUser = location.state?.user;
        const stateAccessToken = location.state?.accessToken;
        const stateIdToken = location.state?.idToken;
        const stateRefreshToken = location.state?.refreshToken;
        const stateClient = location.state?.client;
        if (stateTenant && stateUser) {
            setUser(stateUser);
            setTenant(stateTenant);
            setAccessToken(stateAccessToken);
            setIdToken(stateIdToken);
            setRefreshToken(stateRefreshToken);
            setClient(stateClient);
        }
        // This function exists to satisfy a React warning about useEffect functions being synchronous
        async function fetchTenants() {
            const ts = await getTenants();
            setTenants(ts);
            setIsLoading(false);
            if (ts.length === 1) {
                selectTenant(ts[0]);
            }
        }
        if (tenants.length === 0) {
            fetchTenants();
        }
    // Disabling eslint to remove a warning about selectTenant not being in the dependency array
    // eslint-disable-next-line
    }, [location,
        setAccessToken,
        setClient,
        setIdToken,
        setRefreshToken,
        setTenant,
        setUser,
        tenants]);

    useEffect(() => {
        async function fetchWorkflows(token) {
            const workflows = await getWorkflows(token);
            setWFX(workflows);
            setIsLoading(false);
        }
        if (accessToken) {
            fetchWorkflows(accessToken);
        }
    }, [accessToken])

    const checkAccessToken = async () => {
        try {
            const decodedToken = jwtDecode(accessToken);
            const now = Math.floor(Date.now() / 1000);
            // If the token expires in less than 30 minutes (or has expired), refresh it
            if (decodedToken.exp - now < 1800) {
                const tokenResp = await refreshT(tenant.slug, refreshToken, client.ClientId, client.ClientSecret, tenant.region);
                setIdToken(tokenResp.id_token);
                setAccessToken(tokenResp.access_token);
                return tokenResp.access_token;
            }
            return accessToken;
        } catch (e) {
            console.error(e);
            alert('Error refreshing token');
            alert(e);
            const callback = (stackframes) => {
                const stringifiedStack = stackframes.map(sf => {
                    return sf.toString();
                }).join('\n');
                alert(stringifiedStack);
            }
            const errback = (_) => {
            }
            StackTrace.fromError(e).then(callback).catch(errback);
        }
    }

    const start = async (id) => {
        // Make request to start workflow
        setIsLoading(true);
        try {
            const token = await checkAccessToken();
            const response = await startWorkflow(id, tenant, token);
            if (response['$metadata'].httpStatusCode !== 200) {
                // TODO Error handle this
            }
            history.push('/processing', { arn: response.executionArn, token: response.token, url: response.url })
        } catch (e) {
            console.error(e);
            alert(e);
            const callback = (stackframes) => {
                const stringifiedStack = stackframes.map(sf => {
                    return sf.toString();
                }).join('\n');
                alert(stringifiedStack);
                setIsLoading(false);
            }
            const errback = (_) => {
                setIsLoading(false);
            }
            StackTrace.fromError(e).then(callback).catch(errback);
        }
    }

    const clearLocationState = () => {
        history.replace({ ...history.location, state: undefined });
    }

    const workflowResult = location.state?.success;
    const workflowList = () => {
        return (
            isLoading ? (
                <Loading />
            ) : (
            <>
                <Workflows tenant={tenant} workflows={wfx} startWfx={start} />
            </>
            )
        )
    }
    const result = () => {
        return (
            workflowResult ? (
                <div className={classes.resultContainer}>
                    <img src={RocketImg} alt="Success" />
                    <div>YIPPEE! Your workflow has successfully completed.</div>
                    <Button variant="contained" onClick={() => clearLocationState()}>Continue</Button>
                </div>
            ) : (
                <div className={classes.resultContainer}>
                    <div>Oh no! Your workflow ended in failure!</div>
                    <Button variant="contained" onClick={() => clearLocationState()}>Continue</Button>
                </div>
            )
        );
    }

    const selectTenant = async (t) => {
        setIsLoading(true);
        const resp = await getUserPoolAll(t.slug, t.region);
        t.userPoolId = resp.poolId;
        t.clientId = resp.clientId;
        t.userPool = resp.userPool;
        t.client = resp.client;
        setTenantSelected(true);
        setTenant(t);
    }

    const logout = async () => {
        clearLocationState();
        history.push('/logout')
    }

    const redirect = () => {
        setIsLoading(true);
        history.push('./dipa');
    }

    const pageSelect = () => {
        if (!tenant) {
            return <TenantSelector tenants={tenants} loading={isLoading} onClick={selectTenant} />
        } else if (!user && !location.state?.user) {
            if (tenantSelected) {
                return <UserLogin tenant={tenant} />
            } else {
                setTenant(undefined);
                setIsLoading(false);
            }
        } else {
            return workflowResult === undefined ? workflowList() : result()
        }
    }

    return (
        <>
            <Header user={user} tenant={tenant?.name} logout={logout} redirect={redirect} />
            <div className={classes.page}>{pageSelect()}</div>
        </>
    )
}

export default MFP