import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { getWorkflowUI } from '../../services/api';
import { useInterval } from '../../services/hooks/useInterval';
import Header from '../Header';
import Loading from '../Loading';

const Processing = () => {
    const [isProcessing, setIsProcessing] = useState(true);
    const [delay, setDelay] = useState(0);
    const location = useLocation();
    const history = useHistory();

    useInterval(async () => {
        const arn = location.state?.arn || new URLSearchParams(location.search).get("arn");
        if (!arn) {
            history.push('/', { success: false });
        }
        const response = await getWorkflowUI(arn);
        if (!response.processing) {
            if (response.waitingForInput) {
                setIsProcessing(false);
                const params = new URLSearchParams({
                    arn: response.arn,
                    token: response.token,
                    url: response.callbackUrl,
                    workflowId: response.workflowId,
                    nodeId: response.nodeId,
                    metadata: response.metadata
                });
                window.location = `${response.url}?${params.toString()}`;
            } else if (response.done) {
                history.push('/', { success: response.success });
            }
        }
    }, delay);

    useEffect(() => {
        if (isProcessing) {
            setDelay(2000);
        } else {
            setDelay(0);
        }
    }, [isProcessing])

    return (
        <>
            <Header />
            <Loading />
        </>
    )
}

export default Processing