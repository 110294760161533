import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import StackTrace from 'stacktrace-js';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { revokeToken } from '../../services/api';
import Loading from '../Loading';

const Logout = () => {
    const [_user, setUser] = useLocalStorage('user', '');
    const [tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [_accessToken, setAccessToken] = useLocalStorage('accessToken', undefined);
    const [_idToken, setIdToken] = useLocalStorage('idToken', undefined);
    const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', undefined);
    const [client, setClient] = useLocalStorage('client', undefined);
    const [_, setSqta] = useLocalStorage('dipa-sqta', '');
    const history = useHistory();

    useEffect(() => {
        async function clearData() {
            await setTenant(undefined);
            await setUser('');
            await setAccessToken(undefined);
            await setIdToken(undefined);
            await setRefreshToken(undefined);
            await setClient(undefined);
            await setSqta('');
        }
        async function logoutUser(tenant, client) {
            try {
                if (!tenant)
                    throw new Error('tenant not defined')
                if (!client)
                    throw new Error('client not defined')
    
                    const clientId = client?.ClientId;
                    const redirectUrlBase = `https://${tenant.slug}.auth.${tenant.region}.amazoncognito.com/logout`
    
                    if (tenant && refreshToken && client) {
                        await revokeToken(tenant.slug, refreshToken, client.ClientId, client.ClientSecret, tenant.region);
                    }
    
                    await clearData(); // tenant=null, client=null
    
                    if (clientId) {
                        const params = new URLSearchParams({
                            client_id: clientId,
                            logout_uri: `${window.location.protocol}//${window.location.host}/logout`,
                        })
                        const redirectUrl = `${redirectUrlBase}?${params.toString()}`;
    
                        window.location = redirectUrl
                    } else {
                        history.push('/');
                }
            } catch (e) {
            alert(e);
            const callback = (stackframes) => {
                const stringifiedStack = stackframes.map(sf => {
                    return sf.toString();
                }).join('\n');
                alert(stringifiedStack);
                history.push('/');
            }
            const errback = (_) => {
                history.push('/');
            }
            StackTrace.fromError(e).then(callback).catch(errback);
        }
        }

        if (tenant && client) {
            logoutUser(tenant, client);
        } else {
            history.push('/');
        }
        
    }, [
        client,
        history,
        refreshToken,
        setAccessToken,
        setClient,
        setIdToken,
        setRefreshToken,
        setTenant,
        setUser,
        setSqta,
        tenant
    ]);

    return (
        <Loading/>
    )
}

export default Logout